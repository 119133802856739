<template>
  <div v-if="source && end && value">
    <router-link
      :to="`/${source}/${id}/${end}`"
      >{{ value.length > 0 ? value : name }}</router-link
    >
  </div>
  <div v-else-if="source && value">
    <router-link :to="`/${source}/${id}`">{{
        value
      }}</router-link>
  </div>
  <div v-else>
    <router-link :to="`/${source}/${id}/edit`">{{
        name
      }}</router-link>
  </div>
</template>

<script>
export default {
  props: {
    params: Object,
    val: {
      required: false,
    },
    data_id: {
      type: Number,
      required: false
    },
    dest: {
      type: String,
      required: false,
    },
    data_name: {
      type: String,
      required: false,
    },
    res: {
      type: String,
      required: false,
    },
    group: {
      required: false,
    }
  },
  setup(props) {
    let source = props.dest || props.params?.source;
    let value = props.val || props.params?.value;
    let end = props.res || props.params?.end;
    let name = props.data_name || props.params?.node.data.name;
    let id = props.data_id || props.params?.node.data.id;

    return {
      source,
      value,
      end,
      name,
      id
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  width: 100%;
  display: block;
  &:hover {
    color: var(--hover-color);
  }
}
</style>